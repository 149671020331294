@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "Inter", sans-serif;
    color: white;
    background-position: center -600px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #040404;
    background-image: url("https: //d2xmphtp68wgvd.cloudfront.net/top-ellipse.png");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}

img,
svg,
canvas,
iframe,
video {
    max-width: 100%;
}

a,
span {
    display: inline-flexbox;
}

#root {
    padding: 100px 0;
}

@media (max-width: 499px) {
    #root {
        padding: 30px 0;
    }
}

#root .logo img {
    background-color: #131415;
    padding: 20px 10px;
    border-radius: 10px;
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    height: 200px;
}

@media (min-width: 1400px) {
    .logo h1 {
        font-size: 44px;
    }
}

.logo h1 {
    margin: 20px 0;
}

.container {
    max-width: 900px;
    margin: 0 auto;
}

@media (max-width: 991px) {
    .container {
        max-width: 700px;
    }
}

img:has(+img),
img:has(+img)+img {
    padding: 5px;
    width: 50%;
}

.table {
    white-space: nowrap;
    vertical-align: middle;
    --bs-table-bg: transparent;

    td,
    th {
        color: white;
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }
}

img,
.img {
    flex-shrink: 0;
    width: 360px;
}

p.content {
    color: rgba(255, 255, 255, 0.9);
}

ul li:not(:last-child) {
    margin-bottom: 10px;
}

ul li {
    color: #EBEBF5
}